<template>
  <div>
    <h1 class="title">
      Выбери варежку и получи свой подарок ❄️
    </h1>

    <p class="prize-run__txt prize-run__txt--top">
      {{ `${leftTimeObj.h}:${leftTimeObj.m}:${leftTimeObj.s}` }}
    </p>
    <div class="balls__blocks">
      <div class="balls__block">
        <div class="balls">
          <button
            class="ball ball--beat"
            v-for="i in 9"
            :key="i"
            :class="[{
                'ball--click' : (indexClicked === i - 1),
                'ball--prize' : (prizeSuccess && indexClicked === i - 1) || prizeSuccessAll,
              },
              'ball--' + i
            ]"
            @click="ballClicked(i - 1)"
          >
            <span class="ball__num"></span>
            <div class="lds-ring" v-if="indexClicked === i - 1 && !prizeSuccess">
              <div></div>
            </div>
            <span class="ball__prize-name" v-if="(prizeSuccess && indexClicked === i - 1)  || prizeSuccessAll">
              <span class="ball__prize-name_txt">{{ shuffleArray[i - 1].name }}</span>
            </span>
          </button>
        </div>
      </div>
    </div>

    <div class="prize" v-if="prizeSuccess" ref="prize" :class="{'prize--donation0' : donation === 0}">
      <div class="prize-block">
        <h3 class="prize__title">Поздравляем!</h3>
        <div class="prize-info">
          <div class="prize-info__img-block">
            <img
              :src="img"
              alt=""
              class="prize-info__img"
            />
          </div>
        </div>
        <p class="prize__txt">{{ prize.desc }} {{ prize.add_name }}</p>
        <p class="prize-info__txt" v-if="donation === 0">
            {{ prize.desc2 }}

            <b v-if="prize.promocode">
              {{ prize.promocode }}
            </b>
            
            <template v-if="prize.id === 3140">
              <br>
              или скачайте по
              <a href="http://storage.lerchek.ru/fitness-diary.pdf" v-if="prize.id === 3140" style="color: #000" target="_blank">ссылке</a>
            </template>
          
        </p>
        <div class="prize-philanthropist" v-if="donation">
          <p class="prize-philanthropist__desc">
            {{ !donated ? 'Хотите пожертвовать свой подарок на благотворительность?' : 'Спасибо!' }}
          </p>
          <div class="prize-philanthropist__block">
            <p class="prize-philanthropist__block-txt" v-if="!donated">
              Если Вы дадите свое согласие, подарок не будет отправлен Вам,
              а стоимость этого подарка - {{ donation }} рублей - мы перечислим
              в благотворительный фонд «Вера в Детство».
            </p>
            <p class="prize-philanthropist__block-txt" v-else>
              Вы выбрали пожертвовать свой подарок на благотворительность.
              Мы отправим от Вашего имени {{ donation }} рублей
              в Благотворительный фонд «Вера в Детство».
              О результатах сбора мы сообщим в нашем аккаунте @Letique.cosmetics
            </p>
            <div class="prize-philanthropist__buttons" v-if="!donated">

              <button class="button button--philanthropist" :disabled="donating" @click.prevent="selectDonation">
                Да, я хочу участвовать
              </button>
              <button class="button button--philanthropist" :disabled="donating" @click.prevent="cancelDonation">
                Нет, я выбираю подарок
              </button>
            </div>
          </div>
          <button class="prize-philanthropist__close" @click.prevent="donation = 0" v-if="!donated"></button>
        </div>
        <div class="prize-run">
          <div class="prize-run__txts">
            <p class="prize-run__txt">
              Осталось попыток: {{ innerAvailableWheels }} из {{ productCount }}
            </p>
            <p class="prize-run__txt">
              Осталось времени:
              {{ `${leftTimeObj.h}:${leftTimeObj.m}:${leftTimeObj.s}` }}
            </p>
          </div>
          <button class="button button--prize" @click="updatePage" v-if="leftTime > 0 && innerAvailableWheels">
            Открыть еще раз
          </button>
        </div>
        <div class="prize-agree">
          <label class="prize-agree__label">
            <input
              type="checkbox"
              class="prize-agree__checkbox"
              checked
              disabled
            />
            <div class="prize-agree__txt">
              <span>Я даю свое согласие с&nbsp; <a href="/rules.pdf?v=3" style="color: inherit" target="_blank">Условиями акции</a></span>
            </div>
          </label>
        </div>
      </div>
    </div>
    <template v-if="prizeSuccess && false">
      <h2 class="title title--small title--marathon" ref="next">ХОТИТЕ ВЫИГРАТЬ ЕЩЕ?</h2>
      <p class="txt">Купите ещё один продукт и поучаствуйте в розыгрыше 🎁</p>
      <buy-block />
    </template>
    <prizes />
  </div>
</template>

<script>
import Prizes from './content/Prizes.vue'
import BuyBlock from '@/components/content/BuyBlock.vue'
import prize3139 from '@/assets/images/gloves/3139.png';
import prize3140 from '@/assets/images/gloves/3140.png';
import prize3144 from '@/assets/images/gloves/3144.png';
import prize3145 from '@/assets/images/gloves/3145.png';
import prize3146 from '@/assets/images/gloves/3146.png';
import prize3147 from '@/assets/images/gloves/3147.png';
import prize3152 from '@/assets/images/gloves/3152.png';

export default {
  name: "Balloons",
  props: {
    number: String,
    phone: String,
    prizes: Array,
    leftTime: Number,
    availableWheels: Number,
    productCount: Number,
  },
  components: {
    Prizes,
    BuyBlock,
  },
  data() {
    return {
      indexClicked: null,
      prize: null,
      prizeSuccess: false,
      prizeSuccessAll: false,
      prizePink: false,
      shuffleArray: [],
      leftTimeObj: {},
      innerAvailableWheels: 0,
      profileId: null,
      showPrizeDesc: false,
      prize3139,
      prize3140,
      prize3144,
      prize3145,
      prize3146,
      prize3147,
      prize3152,
      donation: null,
      donationId: null,
      donating: false,
      donated: null,
      orderPrizeId: null,
    };
  },
  created() {
    this.shuffleArray = this.prizes.map((x) => x);
    if (this.shuffleArray.length < 9) {
      for (let i = this.shuffleArray.length; i < 9; i++) {
        this.shuffleArray.push({
          name: 'Промокод на косметику Letique'
        })
      }
    }
    this.innerAvailableWheels = this.availableWheels;
    this.profileId = this.$route.query.marathon_profile_id;
    this.setTimer();
  },
  methods: {
    async ballClicked(index) {
      if (this.indexClicked === null) {
        this.innerAvailableWheels--;
        try {
          this.indexClicked = index;
          let response = await this.$axios.post(
            `https://winter.lerchek.ru/api/lv/lottery/229/prize`,
            {
              number: this.number,
              phone: this.phone,
            }
          );
          if (response.data.success === false) {
            this.$emit("errorPrize");
            this.indexClicked = null;
          } else {
            this.prize = response.data.data.prize;
            this.orderPrizeId = response.data.data.id;
            this.donationId = setTimeout(() => {
              this.donation = null;
            }, 5 * 60 * 1000)
            try {
              let tempDonate = null;
              if (typeof response.data.data.data === 'string') {
                tempDonate = JSON.parse(response.data.data.data)
              } else {
                tempDonate = response.data.data.data;
              }
              this.donation = tempDonate.donate;
              const extraData = JSON.parse(response.data.data.data);
              if (extraData.promocode) {
                this.prize.promocode = extraData.promocode;
              }

            } catch {
              this.donation = 0;
            }
            
            this.shuffle(this.shuffleArray);
            let index = 0;
            for (let prize of this.shuffleArray) {
              if (
                prize.id === this.prize.id
              ) {
                this.shuffleArray.splice(index, 1);
                this.prize.add_name = this.getAddName(response.data.data.data)
                this.prize.promocode = null;
              } else {
                index += 1;
              }
            }
            this.prizeSuccess = true;
            this.shuffleArray.splice(this.indexClicked, 0, this.prize);
            setTimeout(() => {
              this.prizeSuccessAll = true;
            }, 1500);
            
            setTimeout(() => {
             this.$refs.prize.scrollIntoView({behavior: "smooth"});
            }, 6000);
            this.cancelDonation();
          }
        } catch (e) {
          console.log(e);
          this.$emit("errorPrize");
        }
      }
    },
    getAddName(d) {
      try {
        let data = JSON.parse(d);
        if (data && data.name) {
          return data.name;
        }
        return '';
      } catch {
        return '';
      }
    },
    shuffle(array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    },
    setTimer() {
      let leftTime = this.leftTime;
      this.leftTimeObj = this.secondsToTime(leftTime);
      let interval = setInterval(() => {
        leftTime--;
        this.leftTimeObj = this.secondsToTime(leftTime);
        if (leftTime <= 0) {
          clearInterval(interval);
          location.reload();
        }
      }, 1000)
    },
    secondsToTime(secs) {
      var hours = Math.floor(secs / (60 * 60));
      var divisor_for_minutes = secs % (60 * 60);
      var minutes = Math.floor(divisor_for_minutes / 60);
      var divisor_for_seconds = divisor_for_minutes % 60;
      var seconds = Math.ceil(divisor_for_seconds);
      var obj = {
        h: hours < 10 ? ('0' + hours) : hours,
        m: minutes < 10 ? ('0' + minutes) : minutes,
        s: seconds < 10 ? ('0' + seconds) : seconds,
      };
      return obj;
    },
    updatePage() {
      if (this.innerAvailableWheels > 0) {
        location.reload();
      } else {
        this.$refs.next.scrollIntoView({behavior: "smooth"});
      }
      
    },
    async cancelDonation() {
      try {
        clearTimeout(this.donationId);
        this.donating = true;
        const response = await await this.$axios.post('https://winter.lerchek.ru/api/lv/lottery/prize/cancel-donate', {
          number: this.number,
          id: this.orderPrizeId,
        });
        if (response.data.success) {
          this.donation = 0;
          try {
            const extraData = JSON.parse(response.data.data.data);
            if (extraData.promocode) {
              this.prize.promocode = extraData.promocode;
            }
          } catch {
            // 
          }

        } else {
          alert('Ошибка');
        }
      } catch {
        alert('Ошибка');
      } finally {
        this.donating = false;
      }
    },
    async selectDonation() {
      clearTimeout(this.donationId);
      try {
        this.donating = true;
        const response = await await this.$axios.post('https://winter.lerchek.ru/api/lv/lottery/prize/donate', {
          number: this.number,
          id: this.orderPrizeId,
        });
        if (response.data.success) {
          this.donated = true;
        } else {
          alert('Ошибка');
        }
      } catch {
        alert('Ошибка');
      } finally {
        this.donating = false;
      }
    },
  },
  computed: {
    img() {
      if (this['prize' + this.prize.id]) {
        return this['prize' + this.prize.id];
      }
      return null;
    }
  }
};
</script>

<style>

.lds-ring {
  display: inline-block;
  position: relative;
  width: 4rem;
  height: 4rem;
  right: 0.8rem;
  top: -.8rem;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 4rem;
  height: 4rem;
  margin: .8rem;
  border: .8rem solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgba(255, 255, 255, .75) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.prize-run__txt--top {
    text-align: center!important;
    width: 100%!important;
    font-weight: 600 !important;
    font-size: 3rem !important;
    color: #222 !important;
    margin: -4rem 0 4rem;
}
@media (min-width: 768px) {
  .prize-run__txt--top {
    text-align: center;
    font-size: 5rem !important;
    color: #222;
    margin: -2rem 0 4rem
  }
}
body::after{
  position:absolute;
  width:0;
  height:0;
  overflow:hidden;
  z-index:-1;
  content: url("../assets/icons/click.gif") url("../assets/images/paper_white.png") url("../assets/images/paper.png");
}
.container--2 {
  overflow: visible;
}
.bg {
  background-position: top center;
  overflow: hidden;
  width: 100vw;
  min-height: 100vh;
}
.ball--prize .ball__house,
.ball--prize-pink .ball__house {
  opacity: 0;
}
.ball--prize::before {
  opacity: 0.3;
}

@media (max-width: 768px) {
  /*
  .ball--prize span:not(.ball__house){
    font-size: 2rem;
    top: 50%;
    left: 54%;
    transform: translate(-50%, -50%);
    width: 12rem;
    display: block;
  }
  */

  .balls {
    min-height: 39.4rem !important;
  }

}
.ball {
  /* animation: balloon1 6s ease-in-out infinite; */
  animation: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ball--2 {
  animation: balloon2 6s ease-in-out infinite;
}
.ball--3 {
  animation: balloon4 6s ease-in-out infinite;
}
.ball--4 {
  animation: balloon1 5s ease-in-out infinite;
}
.ball--5 {
  animation: balloon3 5s ease-in-out infinite;
}
.ball--6 {
  animation: balloon2 3s ease-in-out infinite;
}
.ball--7 {
  animation: balloon1 6s ease-in-out infinite;
}
.ball--8 {
  animation: balloon2 6s ease-in-out infinite;
}
.ball--9 {
  animation: balloon4 6s ease-in-out infinite;
}
.ball.ball--click,
.ball.ball--prize,
.ball.ball--prize-pink {
  animation: none !important;
}
@keyframes balloon1 {
  0%,
  100% {
    transform: translateY(0) rotate(-6deg);
  }
  50% {
    transform: translateY(-10px) rotate(2deg);
  }
}
@keyframes balloon2 {
  0%,
  100% {
    transform: translateY(0) rotate(6deg);
  }
  50% {
    transform: translateY(-10px) rotate(-2deg);
  }
}
@keyframes balloon3 {
  0%,
  100% {
    transform: translate(0, -10px) rotate(6eg);
  }
  50% {
    transform: translate(-10px, 10px) rotate(-2deg);
  }
}
@keyframes balloon4 {
  0%,
  100% {
    transform: translate(10px, -10px) rotate(-8eg);
  }
  50% {
    transform: translate(-15px, 10px) rotate(2deg);
  }
}
.prize-info__txt b {
  display: block;
  max-width: 250px;
  margin: 0 auto;
  text-align: center;
  background: #fff;
  padding: 1rem 2rem;
  font-weight: 600;
  margin-top: 1rem;
}
@media (max-width: 768px) {
  .prize-info__txt b {
    max-width: 100%;
  }
}
</style>
