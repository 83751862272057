<template>
  <div class="jsModal body__modalText _active" data-modal="fortuna">
  </div>
</template>

<script>
export default {
  name: "Rules",
};
</script>

<style scoped></style>
