<template>
  <header class="header" id="top">
    <div class="header__menu">
      <div class="logo__link">
        <img
          src="@/assets/images/gloves/logo.svg"
          srcset=""
          alt=""
          class="logo"
        />
        <span class="logo__link-lucky"></span>
      </div>
      <div class="header__links">
        <a href="https://lerchek.ru/login" class="header__link" target="_blank"> личный кабинет </a>
        <button class="button__close">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
            <g>
              <g>
                <g />
              </g>
              <g>
                <g transform="rotate(45 9 9)">
                  <path
                    fill="#fff"
                    d="M20 10.117H-2c-.552 0-1-.43-1-.959 0-.529.448-.958 1-.958h22c.552 0 1 .43 1 .958 0 .53-.448.959-1 .959z"
                  />
                </g>
                <g transform="rotate(135 9 9)">
                  <path
                    fill="#fff"
                    d="M-2 8.2h22c.552 0 1 .43 1 .958 0 .53-.448.959-1 .959H-2c-.552 0-1-.43-1-.959 0-.529.448-.958 1-.958z"
                  />
                </g>
              </g>
            </g>
          </svg>
        </button>
      </div>
    </div>
    <div class="popup__menu">
      <div class="popup__menu-links">
        <a href="#top" class="popup__menu-link"> Идеальная фигура </a>
        <a href="#prize" class="popup__menu-link"> Выиграй призы! </a>
        <a href="#ideal" class="popup__menu-link"> Как получить результат? </a>
        <a href="#marathon" class="popup__menu-link"> Удобная платформа </a>
        <a href="#faq" class="popup__menu-link"> FAQ </a>
        <a href="#form" class="popup__menu-link"> Участвовать + подарок </a>
      </div>
    </div>
    <div class="popup__cosmetic">
      <div class="popup__cosmetic-block">
        <p class="popup__cosmetic-txt">
          Вы выбрали Марафон без косметики. При доплате всего 200 ₽ Вы можете
          получить марафон с косметикой.
        </p>
        <p class="popup__cosmetic-txt">
          Вы уверены, что хотите приобрести марафон без косметики?
        </p>
        <div class="popup__cosmetic-buttons">
          <button class="popup__cosmetic-button">Нет</button>
          <button class="popup__cosmetic-button">Да</button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style scoped></style>
