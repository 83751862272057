<template>
  <div>
      <h1 class="title">
       Выбери варежку и получи свой подарок ❄️
			</h1>
      <div class="balls__blocks">
          <div class="balls__block">
              <div class="popup__error">
                <div class="popup__error-block">
                  <span class="popup__error-icon"></span>
                  <div class="popup__error-links">
                    <p class="popup__error-link">Произошла ошибка при загрузке, напишите нам на <a href="mailto:info@lerchek.ru">info@lerchek.ru</a></p>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
  export default {
    name: "Error"
  }
</script>
