<template>
  <div class="jsModal body__modalText _active" data-modal="policy">
    <div class="modalText">
      <div class="modalText__wrapper">
        <img
          src="@/assets/images/cancel-black.png"
          alt=""
          class="jsClose modalText__close"
          @click="$emit('close')"
        />
        <p class="modalText__title">Обработка персональных данных</p>
        <p class="modalText__subtitle"></p>
        <p class="modalText__title">1.НАЗНАЧЕНИЕ И ОБЛАСТЬ ДЕЙСТВИЯ</p>
        <p class="modalText__text">
          1.1. Настоящий документ (далее — Политика) определяет цели и общие
          принципы обработки персональных данных, а также реализуемые меры
          защиты персональных данных в ИП (далее — Оператор). Политика является
          общедоступным документом Оператора и предусматривает возможность
          ознакомления с ней любых лиц.
        </p>
        <p class="modalText__text">
          1.2. Политика действует бессрочно после утверждения и до ее замены
          новой версией.
        </p>
        <p class="modalText__text">
          1.3. В Политике используются термины и определения в соответствии с их
          значениями, как они определены в ФЗ-152 «О персональных данных».
        </p>
        <p class="modalText__text">
          1.4. Политика распространяется на всех сотрудников Оператора (включая
          работников по трудовым договорам и сотрудников, работающих по
          договорам подряда) и все структурные подразделения Общества, включая
          обособленные подразделения. Требования Политики также учитываются и
          предъявляются в отношении иных лиц при необходимости их участия в
          процессе обработки персональных данных Оператором, а также в случаях
          передачи им в установленном порядке персональных данных на основании
          соглашений, договоров, поручений на обработку.
        </p>

        <p class="modalText__title">
          2. ПРИНЦИПЫ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
        </p>

        <p class="modalText__text">
          2.1. Обработка персональных данных Оператором осуществляется на
          законной и справедливой основе.
        </p>

        <p class="modalText__text">
          2.2. Обработка персональных данных ограничивается достижением
          конкретных, заранее определенных и законных целей. Не допускается
          обработка персональных данных, несовместимая с целями сбора
          персональных данных.
        </p>

        <p class="modalText__text">
          2.3. Не допускается объединение баз данных, содержащих персональные
          данные, обработка которых осуществляется в целях, несовместимых между
          собой.
        </p>

        <p class="modalText__text">
          2.4. Обработке подлежат только персональные данные, которые отвечают
          целям их обработки.
        </p>

        <p class="modalText__text">
          2.5. Содержание и объем обрабатываемых персональных данных
          соответствуют заявленным целям обработки. Обрабатываемые персональные
          данные не являются избыточными по отношению к заявленным целям их
          обработки.
        </p>

        <p class="modalText__text">
          2.6. При обработке персональных данных обеспечены точность
          персональных данных, их достаточность, а в необходимых случаях и
          актуальность по отношению к целям обработки персональных данных.
          Оператор принимает необходимые меры по удалению или уточнению неполных
          или неточных данных.
        </p>

        <p class="modalText__text">
          2.7. Хранение персональных данных осуществляется в форме, позволяющей
          определить субъекта персональных данных, не дольше, чем этого требуют
          цели обработки персональных данных, если срок хранения персональных
          данных не установлен федеральным законом, договором, стороной
          которого, выгодоприобретателем или поручителем по которому является
          субъект персональных данных. Обрабатываемые персональные данные
          подлежат уничтожению либо обезличиванию по достижении целей обработки
          или в случае утраты необходимости в достижении этих целей, если иное
          не предусмотрено федеральным законом.
        </p>

        <p class="modalText__title">3. УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</p>

        <p class="modalText__text">
          Обработка персональных данных должна осуществляться с соблюдением
          принципов и правил, предусмотренных настоящим Федеральным законом.
          Обработка персональных данных допускается в следующих случаях:
        </p>

        <p class="modalText__text">
          3.1 Обработка персональных данных осуществляется с согласия субъекта
          персональных данных на обработку его персональных данных;
        </p>

        <p class="modalText__text">
          3.2 Обработка персональных данных необходима для достижения целей,
          предусмотренных международным договором Российской Федерации или
          законом, для осуществления и выполнения возложенных законодательством
          Российской Федерации на оператора функций, полномочий и обязанностей;
        </p>

        <p class="modalText__text">
          3.3 Обработка персональных данных необходима для исполнения договора,
          стороной которого либо выгодоприобретателем или поручителем по
          которому является субъект персональных данных, в том числе в случае
          реализации оператором своего права на уступку прав (требований) по
          такому договору, а также для заключения договора по инициативе
          субъекта персональных данных или договора, по которому субъект
          персональных данных будет являться выгодоприобретателем или
          поручителем; (в ред. Федерального закона от 21.12.2013 N 363-ФЗ)
        </p>

        <p class="modalText__title">4. ПРАВА СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ</p>

        <p class="modalText__text">
          4.1. Субъект персональных данных имеет право отозвать согласие на
          обработку персональных данных, направив соответствующий запрос
          Оператору по почте или обратившись лично.
        </p>

        <p class="modalText__text">
          4.2. Субъект персональных данных имеет право на получение информации,
          касающейся обработки его персональных данных, в том числе содержащей:
          - подтверждение факта обработки персональных данных Оператором; -
          правовые основания и цели обработки персональных данных; - цели и
          применяемые Оператором способы обработки персональных данных; -
          наименование и место нахождения Оператора, сведения о лицах (за
          исключением сотрудников/работников Оператора), которые имеют доступ к
          персональным данным или которым могут быть раскрыты персональные
          данные на основании договора с Оператором или на основании
          федерального закона; - обрабатываемые персональные данные, относящиеся
          к соответствующему субъекту персональных данных, источник их
          получения, если иной порядок представления таких данных не
          предусмотрен федеральным законом; - сроки обработки персональных
          данных, в том числе сроки их хранения; - порядок осуществления
          субъектом персональных данных прав, предусмотренных Федеральным
          законом «О персональных данных»; - информацию об осуществленной или о
          предполагаемой трансграничной передаче данных; - наименование или
          фамилию, имя, отчество и адрес лица, осуществляющего обработку
          персональных данных по поручению Оператора, если обработка поручена
          или будет поручена такому лицу; - иные сведения, предусмотренные
          Федеральным законом «О персональных данных» или другими федеральными
          законами.
        </p>

        <p class="modalText__text">
          4.3. Субъект персональных данных вправе требовать от Оператора
          уточнения его персональных данных, их блокирования или уничтожения в
          случае, если персональные данные являются неполными, устаревшими,
          неточными, незаконно полученными или не являются необходимыми для
          заявленной цели обработки, а также принимать предусмотренные законом
          меры по защите своих прав.
        </p>

        <p class="modalText__text">
          4.4. Если субъект персональных данных считает, что Оператор
          осуществляет обработку его персональных данных с нарушением требований
          Федерального закона «О персональных данных» или иным образом нарушает
          его права и свободы, субъект персональных данных вправе обжаловать
          действия или бездействие Оператора в уполномоченный орган по защите
          прав субъектов персональных данных (Федеральная служба по надзору в
          сфере связи, информационных технологий и массовых коммуникаций —
          Роскомнадзор) или судебном порядке.
        </p>

        <p class="modalText__text">
          4.5. Субъект персональных данных имеет право на защиту своих прав и
          законных интересов, в том числе на возмещение убытков и (или)
          компенсацию морального вреда в судебном порядке.
        </p>

        <p class="modalText__title">
          5. МЕРЫ ПО ОБЕСПЕЧЕНИЮ БЕЗОПАСНОСТИ ПЕРСОНАЛЬНЫХ ДАННЫХ
        </p>

        <p class="modalText__text">
          5.1. Оператор при обработке персональных данных принимает необходимые
          правовые, организационные и технические меры для защиты персональных
          данных от неправомерного или случайного доступа к ним, уничтожения,
          изменения, блокирования, копирования, предоставления, распространения
          персональных данных, а также от иных неправомерных действий в
          отношении персональных данных.
        </p>

        <p class="modalText__text">
          5.2. Обеспечение безопасности персональных данных достигается, в
          частности: - определением угроз безопасности персональных данных при
          их обработке в информационных системах персональных данных; -
          применением организационных и технических мер по обеспечению
          безопасности персональных данных при их обработке в информационных
          системах персональных данных, необходимых для выполнения требований к
          защите персональных данных, исполнение которых обеспечивает
          установленные Правительством Российской Федерации уровни защищенности
          персональных данных; - применением прошедших в установленном порядке
          процедуру оценки соответствия средств защиты информации; - оценкой
          эффективности принимаемых мер по обеспечению безопасности персональных
          данных до ввода в эксплуатацию информационной системы персональных
          данных; - учетом машинных носителей персональных данных; -
          обнаружением фактов несанкционированного доступа к персональным данным
          и принятием мер; - восстановлением персональных данных,
          модифицированных или уничтоженных вследствие несанкционированного
          доступа к ним; - установлением правил доступа к персональным данным,
          обрабатываемым в информационной системе персональных данных, а также
          обеспечением регистрации и учета всех действий, совершаемых с
          персональными данными в информационной системе персональных данных; -
          контролем за принимаемыми мерами по обеспечению безопасности
          персональных данных и уровня защищенности информационных систем
          персональных данных.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Policy",
};
</script>

<style scoped></style>
