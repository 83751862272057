<template>
  <div class="bg">
    <Header />
    <div class="container" :class="{'container--error' : hasError}">
      <Loading v-if="displayLoading" />
      <Error3 v-if="displayError3" />
      <Error v-if="displayError" />
      <Error2 v-if="displayError2" />
      <Error4 v-if="displayError4" />

      <template v-if="(displayError4 || displayError2) && false">
        <h2 class="title title--small">ХОТИТЕ ВЫИГРАТЬ ЕЩЕ ПРИЗ?</h2>
        <p class="txt">Купите ещё один продукт и поучаствуйте в розыгрыше 🎁</p>
        <buy-block />
      </template>

      <Prizes v-if="displayError4 || displayError2" />
      <Balloons
        v-if="displayBalloons"
        :number="number"
        :phone="phone"
        @errorPrize="
          displayError = true;
          displayBalloons = false;
        "
        :prizes="prizes"
        :leftTime="leftTime"
        :availableWheels="availableWheels"
        :productCount="productCount"
        @displayRules="displayRules = !displayRules"
        @displayPolicy="displayPolicy = !displayPolicy"
      />
    </div>
    <Footer
      @displayRules="displayRules = !displayRules"
      @displayPolicy="displayPolicy = !displayPolicy"
    />
    <div class="popup__privacy" v-if="showPrivacy">
      <div class="popup__privacy-links">
        <p class="popup__privacy-link">Участвуя в игре, Вы соглашаетесь с&nbsp;
          <a href="/rules.pdf?v=3" target="_blank">Условиями Акции</a>
        </p>
      </div>
      <button class="popup__privacy-close" @click.prevent="showPrivacy = false"></button>
    </div>
    <Rules v-if="displayRules" @close="displayRules = false" />
    <Policy v-if="displayPolicy" @close="displayPolicy = false" />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Rules from "@/components/Rules";
import Policy from "@/components/Policy";
import Loading from "@/components/Loading";
import Error from "@/components/Error";
import Error3 from "@/components/Error3";
import Error4 from "@/components/Error4";
import Error2 from "@/components/Error2";
import Balloons from "@/components/Balloons";
import Prizes from '@/components/content/Prizes.vue'
import BuyBlock from '@/components/content/BuyBlock.vue'
export default {
  name: "Main",
  components: {
    Header,
    Footer,
    Rules,
    Loading,
    Policy,
    Error,
    Error2,
    Error3,
    Error4,
    Balloons,
    Prizes,
    BuyBlock,
  },
  data() {
    return {
      displayLoading: true,
      displayRules: false,
      displayPolicy: false,
      displayCongratulations: false,
      displayError: false,
      displayError2: false,
      displayError4: false,
      displayBalloons: false,
      number: null,
      phone: null,
      leftTime: null,
      availableWheels: null,
      productCount: null,
      prizes: null,
      displayError3: false,
      showPrivacy: true,
      profileId: null,
    };
  },
  async created() {
    this.profileId = this.$route.query.marathon_profile_id;
    document.title = "Lerchek Lucky";
    if (this.$route.query.number) this.number = this.$route.query.number;
    if (this.$route.query.phone) this.phone = this.$route.query.phone;
    if (this.phone !== null && this.number !== null) {
      await this.getStatus();
      this.displayLoading = false;
    } else {
      this.displayError = true;
      this.displayLoading = false;
    }
  },
  methods: {
    async getStatus() {
      try {
        let response = await this.$axios.get(
          `https://winter.lerchek.ru/api/lv/lottery/229?number=${this.number}&phone=${this.phone}`
        );
        if (response.data.success === false) {
          this.displayError = true;
        } else {
          this.leftTime = response.data.data.order.left_time;
          this.availableWheels = response.data.data.order.available_wheels;
          this.productCount = response.data.data.order.product_count;
          if (this.availableWheels <= 0) {
            this.displayError2 = true;
          } else if (this.leftTime <= 0) {
            this.displayError4 = true;
          } else {
            this.displayBalloons = true;
            this.prizes = response.data.data.info.prizes;
          }
        }
      } catch (e) {
        this.displayError3 = true;
      }
    },
  },
  computed: {
    hasError() {
      return this.displayError || this.displayError2 || this.displayError3 || this.displayError4;
    },
  },
};
</script>

<style>
.footer {
  z-index: 500;
}
</style>
